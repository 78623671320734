@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-btn-primary {
  background-color: #1677ff;
  border-color: #1677ff;
  color: #fff;
  &:hover {
    background-color: #1677ff;
    border-color: #1677ff;
    color: #fff;
  }
}
